const $languageSelect = $('.language-select button');
const $languageSelectDropdown = $('.language-select__dropdown');

function init() {
    $languageSelect.click(function () {
        $languageSelectDropdown.slideToggle('fast');
        console.log('click');
    });
}

$(function () {
    init();
});